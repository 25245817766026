.loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  background-color: white;
}

.loading .container .dot {
  animation: loading 1s infinite alternate;
}

@keyframes loading {
  0% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(5px);
  }
}

.loading .container .dot:nth-child(1) {
  animation-delay: -0.25s;
}

.loading .container .dot:nth-child(2) {
  animation-delay: -0.5s;
}

.loading .container .dot:nth-child(3) {
  animation-delay: -0.75s;
}

.loading .container .dot:nth-child(4) {
  animation-delay: -1s;
}

.sidenav {
  position: fixed;
  width: 300px;
  left: 0;
  top: 0;
  margin: 0;
  transform: translateX(-100%);
  height: 100%;
  padding-bottom: 60px;
  background-color: #fff;
  z-index: 999;
  overflow-y: auto;
  will-change: transform;
  backface-visibility: hidden;
  transition: transform 0.3s ease-out;
}

.sidenav.right-aligned {
  right: 0;
  transform: translateX(100%);
  left: auto;
}

.sidenav.sidenav-fixed {
  left: 0;
  transform: translateX(0);
  position: fixed;
}

.sidenav.sidenav-fixed.right-aligned {
  right: 0;
  left: auto;
}

.sidenav li {
  float: none;
  line-height: 48px;
}

.sidenav li > a {
  color: rgba(0, 0, 0, 0.87);
  display: block;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  line-height: 48px;
  padding: 0 32px;
}

.sidenav li.active {
  background-color: rgba(0, 0, 0, 0.05);
}

.sidenav-trigger {
  cursor: pointer;
}

.sidenav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  height: 120vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 997;
  display: none;
  transition: opacity 0.3s ease-out;
}

.drag-target {
  height: 100%;
  width: 10px;
  position: fixed;
  top: 0;
  z-index: 998;
}

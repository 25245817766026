ul {
    list-style: none;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

#social {
    text-align: center;
    position: absolute;
    bottom: 14%;
    width: 100%;
}

#social p {
    margin-bottom: 10px;
}

#social ul,
#social li {
    display: inline-block;
}

ul.flip {
    position: relative;
    float: left;
    margin: 5px;
    width: 60px;
    height: 40px;
    font-size: 30px;
    font-weight: bold;
    line-height: 40px;
    border-radius: 6px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .7);
}

ul.flip li {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

ul.flip li:first-child {
    z-index: 2;
}

ul.flip li {
    display: flex; 
    flex-direction: column;
}

ul.flip li div {
    z-index: 1;
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
    overflow: hidden;
}

ul.flip li div .shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
}

ul.flip li div.up {
    transform-origin: 50% 100%;
    top: 0;
}

ul.flip li div.up:after {
    content: "";
    position: absolute;
    top: 20px;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 3px;
    background-color: rgba(0, 0, 0, .4);
}

ul.flip li div.down {
    transform-origin: 50% 0%;
    bottom: 0;
}

ul.flip li div.inn {
    position: absolute;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 200%;
    color: #ccc;
    text-shadow: 0 1px 2px #000;
    text-align: center;
    background-color: #333;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
}

ul.flip li div.up div.inn {
    top: 0;
}

ul.flip li div.down div.inn {
    bottom: 0;
}

body.play ul li.before {
    z-index: 3;
}

body.play ul li.active {
    animation: asd .5s .5s linear both;
    z-index: 2;
}

@keyframes asd {
    0% {
        z-index: 2;
    }

    5% {
        z-index: 4;
    }

    100% {
        z-index: 4;
    }
}

body.play ul li.active .down {
    z-index: 2;
    animation: turn .5s .5s linear both;
}

@keyframes turn {
    0% {
        transform: rotateX(90deg);
    }

    100% {
        transform: rotateX(0deg);
    }
}

body.play ul li.before .up {
    z-index: 2;
    animation: turn2 .5s linear both;
}

@keyframes turn2 {
    0% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(-90deg);
    }
}

body.play ul li.before .up .shadow {
    background: -moz-linear-gradient(top, rgba(0, 0, 0, .1) 0%, rgba(0, 0, 0, 1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, .1)), color-stop(100%, rgba(0, 0, 0, 1)));
    background: linear-gradient(top, rgba(0, 0, 0, .1) 0%, rgba(0, 0, 0, 1) 100%);
    animation: shadow2 .5s linear both;
}

body.play ul li.active .down .shadow {
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 1)), color-stop(100%, rgba(0, 0, 0, 0)));
    background: linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    animation: shadow .5s .5s linear both;
}

@keyframes shadow {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes shadow2 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
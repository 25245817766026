input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid #d1d5db;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    position: relative;
}

input[type="radio"]:checked {
    border-color: #FF9800;
}

input[type="radio"]:checked::after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    background-color: #FF9800; 
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

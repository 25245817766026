.paper_texture_game {
    background-image: url('@/assets/img/game/background_desktop.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    top: -30px;
    left: -30px;
    filter: blur(30px);
    transition: filter 0.3s ease-in-out; 
}

.loading-text {
    transition: opacity 0.3s ease-in-out;
}

@media (max-width: 768px) {
    .paper_texture_game {
        background-image: url('@/assets/img/game/background_mobile.png');
    }
}
